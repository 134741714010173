exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mi-perfil-tsx": () => import("./../../../src/pages/mi-perfil.tsx" /* webpackChunkName: "component---src-pages-mi-perfil-tsx" */),
  "component---src-pages-modern-tsx": () => import("./../../../src/pages/modern.tsx" /* webpackChunkName: "component---src-pages-modern-tsx" */),
  "component---src-templates-calculator-page-calculator-page-tsx": () => import("./../../../src/templates/calculator-page/calculator-page.tsx" /* webpackChunkName: "component---src-templates-calculator-page-calculator-page-tsx" */),
  "component---src-templates-calculator-result-page-calculator-result-page-tsx": () => import("./../../../src/templates/calculator-result-page/calculator-result-page.tsx" /* webpackChunkName: "component---src-templates-calculator-result-page-calculator-result-page-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../../../src/templates/category-page.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-checkout-page-checkout-page-tsx": () => import("./../../../src/templates/checkout-page/checkout-page.tsx" /* webpackChunkName: "component---src-templates-checkout-page-checkout-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-login-page-login-page-tsx": () => import("./../../../src/templates/login-page/login-page.tsx" /* webpackChunkName: "component---src-templates-login-page-login-page-tsx" */),
  "component---src-templates-migration-page-user-migration-page-tsx": () => import("./../../../src/templates/migration-page/user-migration-page.tsx" /* webpackChunkName: "component---src-templates-migration-page-user-migration-page-tsx" */),
  "component---src-templates-my-account-page-my-addresses-page-tsx": () => import("./../../../src/templates/my-account-page/my-addresses-page.tsx" /* webpackChunkName: "component---src-templates-my-account-page-my-addresses-page-tsx" */),
  "component---src-templates-order-page-order-page-tsx": () => import("./../../../src/templates/order-page/order-page.tsx" /* webpackChunkName: "component---src-templates-order-page-order-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-recover-password-page-recover-password-page-tsx": () => import("./../../../src/templates/recover-password-page/recover-password-page.tsx" /* webpackChunkName: "component---src-templates-recover-password-page-recover-password-page-tsx" */),
  "component---src-templates-register-register-code-verification-tsx": () => import("./../../../src/templates/register/register-code-verification.tsx" /* webpackChunkName: "component---src-templates-register-register-code-verification-tsx" */),
  "component---src-templates-register-register-page-tsx": () => import("./../../../src/templates/register/register-page.tsx" /* webpackChunkName: "component---src-templates-register-register-page-tsx" */),
  "component---src-templates-terms-page-tsx": () => import("./../../../src/templates/terms-page.tsx" /* webpackChunkName: "component---src-templates-terms-page-tsx" */)
}

