const theme = {
	breakpoints: ['750px', '1080px', '1366px', '1920px'],
	colors: {
		black: '#000000',
		primary: '#292929',
		text: '#5A5A5A',
		menu: '#757575',
		darkgray: '#999999',
		muted: '#E6E6E6',
		lightgray: '#F3F3F3',
		lightergray: '#F9F9F9',
		background: '#F7F7F7',
		white: '#ffffff',
		facebook: '#3b5998',
		twitter: '#0084b4',
		youtube: '#c4302b',
		github: '#211F1F',
		instagram: '#cd486b',
		linkedin: '#0e76a8',
		badge: '#FF5A5F',

		// calculator colors
		deepBlue: "#032F54", 
		mouse: "#3B3942",
		mouseLight: "#595959",
		darkBlack: "#141414",
		black2: "#202020",
		errorLight: "#FFE3E7",
		error: "#E4423F",
		yellowLight: "#FFEBD0",
		yellow: "#FFB042",
		greenLight: "#E6F2EB",
		green: "#29D36B",
		lightGreen: "#A6FFDD",
		gray: "#A6A6A6",
		disabled: "#EBEBEB",
		dirtyWhite: "#FAFAFA",
	},
	fonts: {
		body: 'Open Sans, sans-serif',
		heading: 'Poppins, serif',
		redHatDisplay: 'Red Hat Display, sans-serif',
	},
	fontSizes: [13, 15, 19, 20, 30],
	lineHeights: {
		body: 'initial',
		heading: 'initial',
	},
	fontWeights: {
		body: 400,
		heading: 700,
	},
	sizes: {
		container: '1240px',
	},
	buttons: {
		primary: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			minWidth: [130, 172],
			minHeight: [40, 50],
			borderRadius: 6,
			fontWeight: 700,

			'&:active': {
				transition: 'opacity 0.2s ease',
				svg: {
					width: [16, 18],
					height: [16, 18],
					marginLeft: 10,
				},
				':focus': {
					outline: 'none',
				},
				':hover': {
					opacity: 0.9,
				},
			},
			'&:disabled': {
				backgroundColor: '#c0c0c0',
			},
		},
		white: {
			color: 'primary',
			backgroundColor: 'white',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			minWidth: [130, 172],
			minHeight: [40, 50],
			borderRadius: 6,
			fontWeight: 700,
			textTransform: 'capitalize',
			transition: 'all 0.2s ease',
			svg: {
				width: [16, 18],
				height: [16, 18],
				marginLeft: 10,
			},
			':focus': {
				outline: 'none',
			},
			':hover': {
				backgroundColor: 'lightgray',
			},
		},
		badge: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			borderRadius: 6,
			minWidth: 38,
			minHeight: 28,
			backgroundColor: 'lightgray',
			padding: 0,
			transition: 'background-color 0.2s ease',
			':focus': {
				outline: 'none',
			},
			':hover': {
				backgroundColor: 'muted',
			},
		},
		text: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			borderRadius: 6,
			minWidth: 48,
			minHeight: 48,
			color: 'primary',
			backgroundColor: 'transparent',
			padding: 0,
			svg: {
				width: 30,
				height: 30,
			},
			':focus': {
				outline: 'none',
			},
		},
		border: {
			color: 'primary',
			backgroundColor: 'white',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			minWidth: [130, 172],
			minHeight: [40, 50],
			borderRadius: 6,
			fontWeight: 700,
			transition: 'all 0.2s ease',
			svg: {
				width: [16, 18],
				height: [16, 18],
				marginLeft: 10,
			},
			':focus': {
				outline: 'solid 2px lightgray',
			},
			':hover': {
				backgroundColor: 'lightgray',
			},
		},
		borderText: {
			color: 'primary',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			borderRadius: 6,
			fontWeight: 700,
			transition: 'all 0.2s ease',
			backgroundColor: 'lightgray',
			svg: {
				width: [16, 18],
				height: [16, 18],
				marginLeft: 10,
			},
			':focus': {
				outline: 'solid 2px lightgray',
			},
			':hover': {
				backgroundColor: 'lightgray',
			},
		},
		darkBlueRounded: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			cursor: 'pointer',
			minHeight: [38, 40],
			width: '100%',
			borderRadius: '50px',
			backgroundColor: '#032F54',
			color: 'white',
			fontWeight: 600,
			fontSize: 16,
			lineHeight: "normal",
			fontFamily: 'redHatDisplay',

			'&:disabled': {
				backgroundColor: '#EBEBEB',
				color: '#A6A6A6',
				cursor: 'not-allowed',
				mixBlendMode: 'multiply',
			},
		},
		leftIcon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			minWidth: [150, 174],
			minHeight: [39, 36],
			padding: ["0 12px", "0 22px 0 12px"],
			backgroundColor: '#FAFAFA',
			color: '#202020',
			fontWeight: "600",
			fontSize: "13px",
			lineHeight: "normal",
			fontFamily: 'redHatDisplay',
			borderRadius: '50px',
			border: '1px solid #032F54',
			cursor: 'pointer',
		},
	},
	messages: {
		description: {
			color: 'white',
			backgroundColor: '#132743',
			borderRadius: 6,
			padding: 10,
			marginBottom: 10,
		},
	},
	layout: {
		container: {
			paddingX: '20px',
		},
	},
};
export default theme;
